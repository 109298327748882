import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { User } from './_models/user';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  public serverUrl = isDevMode() ? '/notifications' : environment.backend.baseURL + '/notifications';
  public userData: User;
  public userID;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private authService: AuthService,
    private storage: StorageMap
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      })
  }

  get isLoggedIn() { return this.authService.isLoggedIn(); }

  requestPermission() {
    this.getUserData();
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.storeToken(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  public getUserData() {
    return this.storage.get('data').subscribe((data: User) => { this.userData = data; });
  }

  async storeToken(token) {
    if (this.isLoggedIn) {
      return await this.http.post<any>(`${this.serverUrl}`, { token: token, user_id: this.userData.id }, this.httpOptions).toPromise().then(response => { });
    }
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      })
  }
}