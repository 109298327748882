<section class="login-wrap mtb-40">
    <div class="container">
        <div class="row justify-content-center" *ngIf="!error">
            <div class="col-md-6">
                <div class="login-box">
                    <h1>Prijava</h1>
                    <div class="alert alert-danger alert-dismissible fade show" *ngIf="loginError">
                        {{loginError}}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <mat-form-field class="form-group">
                            <input matInput [type]="showPassword.checked ? 'text' : 'password'"
                                formControlName="account_id" class="form-control" placeholder="Account ID" min="0"
                                required autocomplete="current-password">
                            <mat-error
                                *ngIf="account_id.invalid && (account_id.dirty || account_id.touched) && account_id.errors">
                                Account ID je obavezno polje!</mat-error>
                        </mat-form-field>

                        <mat-checkbox #showPassword color="warn" value="after" class="form-group"><i
                                class="fas fa-eye"></i> Prikaži</mat-checkbox>
                        <div></div>
                        <mat-form-field class="form-group">
                            <input matInput type="email" formControlName="player_email" class="form-control"
                                placeholder="Email" [email]="true" required autocomplete="username">
                            <mat-error
                                *ngIf="player_email.invalid && (player_email.dirty || player_email.touched) && player_email.errors">
                                Email je obavezno polje!</mat-error>
                        </mat-form-field>
                        <div class="form-group">
                            <button mat-raised-button type="submit"
                                [disabled]="formatToken(recentToken) == null && !loginForm.valid" color="warn"
                                (click)="executeAction('login')"><i class="fas fa-sign-in-alt"></i> Uloguj se</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>