<!-- HEADER -->
<header class="header">
  <!-- LOGO -->
  <div class="logo lfloat">
    <a href="/" class="mob"><img src="./assets/logo.svg" alt="MeridianBet"></a>
    <a href="/" class="desk"><img src="./assets/logo.svg" alt="MeridianBet"></a>
  </div>
  <!-- NAVIGACIJA -->
  <nav class="lfloat">
    <ul>
      <li><a href="/">Tipovanje</a></li>
      <li [routerLinkActive]="'is-active'" *ngIf="isLoggedIn"><a href="/rang-lista">Rang lista</a></li>
      <li [routerLinkActive]="'is-active'" *ngIf="isLoggedIn"><a routerLink="/profil">Moj nalog</a></li>
      <li [routerLinkActive]="'is-active'" *ngIf="isLoggedIn && this.inLeague"><a routerLink="/liga">Moja liga</a></li>
      <li [routerLinkActive]="'is-active'"><a href="/pravila">Pravila</a></li>
    </ul>
  </nav>
  <!-- PRIJAVA -->
  <div class="prijava-box rfloat">
    <a href="/uloguj-se" class="prijava rfloat desk" *ngIf="!isLoggedIn"><i class="fas fa-sign-in-alt"></i> Prijava</a>
    <a href="https://meridianbet.rs/sr/registracija?affiliate=200127&campaign=6677&refer=QTk1NzM0QkVFQkE2QjI0NkMwNjEzNENDMjA1QTczQjc="
      class="prijava rfloat desk" *ngIf="!isLoggedIn">
      <i class="fas fa-user-plus"></i> Registruj se
    </a>
    <a routerLink="/" class="prijava rfloat desk" (click)="logout" *ngIf="isLoggedIn">
      <i class="fas fa-sign-out-alt"></i> Izloguj se
    </a>
    <a href="/profil" class="myaccount rfloat mob"></a>
    <!-- <a href="/profile" class="rfloat mob nav"></a> -->
  </div>
</header>
<!-- DESKTOP HEADER -->