import { Component, OnInit, isDevMode } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TicketService } from '../ticket/ticket.service';
import { Amount } from '../_models/amount';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public username: any;
  public amount = new Amount();
  public siteRegisterAddress = environment.siteRegisterAddress;
  public inLeague: any;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(private authService: AuthService, private storage: StorageMap, private http: HttpClient, private ticket: TicketService) { }

  ngOnInit() {
    this.getAmmount();
    if (this.isLoggedIn) {
      this.isInLeague();
    }
  }

  public async firstName() {
    if (this.isLoggedIn) {
      const usr = this.storage.get('data').subscribe((user) => {
        this.username = user['player_firstname'];
      });
    }
  }

  get isLoggedIn() { return this.authService.isLoggedIn(); }

  get logout() {
    return this.authService.logout();
  }

  public async isInLeague() {
    const isInLeagueURL = isDevMode() ? '/is_in_league' : environment.backend.baseURL + '/is_in_league';
    await this.http.post(isInLeagueURL, {}, this.httpOptions).toPromise().then((res) => {
      this.inLeague = res;
    });
    return this.inLeague;
  }

  public async getAmmount() {
    const getAmountURL = isDevMode() ? '/get_amount' : environment.backend.baseURL + '/get_amount';
    return await this.authService.getAuthorizationToken() != null
      ? this.http.post<Amount>(getAmountURL, {}).subscribe(
        (res) => {
          this.amount.daily = res.daily;
          this.amount.monthly = res.monthly;
        }
      ) : this.amount.daily = 0, this.amount.monthly = 0;
  }

}
