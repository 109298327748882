import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { OnExecuteData, ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  public error: string;
  recaptchaToken: any;
  public recentToken = '';
  public readonly executionLog: OnExecuteData[] = [];
  private allExecutionsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private recaptchaV3Service: ReCaptchaV3Service,
    public googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      account_id: ['', Validators.required],
      player_email: ['', Validators.email]
    });
    this.allExecutionsSubscription = this.recaptchaV3Service.onExecute
      .subscribe((data) => this.executionLog.push(data));
    this.authService.logout();
  }

  get account_id() { return this.loginForm.get('account_id'); }
  get player_email() { return this.loginForm.get('player_email'); }

  get loginError() {
    return this.authService.loginResponse != null
      && typeof this.authService.loginResponse !== 'object'
      ? this.authService.loginResponse : null;
  }

  onSubmit() {
    this.submitted = true;
    this.googleAnalyticsService.eventEmitter('login', 'login', 'login', 'click', 1);
    if (this.recaptchaToken !== null && this.account_id.value !== null && this.player_email.value !== null) {
      this.authService.login(this.account_id.value, this.player_email.value).subscribe(
        (data) => {
          if (this.authService.isLoggedIn()) {
            const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
            this.router.navigate([redirect]);
          } else {
            if (data !== undefined && data.error !== undefined) {
              this.error = data.error;
            }
          }
        });
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  public ngOnDestroy() {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service.execute(action)
      .subscribe((token) => this.recentToken = token);
  }


  handleToken(token: string) {
    return this.recaptchaToken;
  }
  public formatToken(token: string): string {
    if (!token) {
      return null;
    }

    return `${token.substr(0, 7)}...${token.substr(-7)}`;
  }

}
