import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Ticket, MyTickets } from '../_models/ticket';
import { Amount } from '../_models/amount';
import { Observable } from 'rxjs';
import { GoogleAnalyticsService } from '../google-analytics.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class TicketService {
  [x: string]: any;
  public home: any;
  public away: any;
  public selection: any;
  public odd: any;
  public EventID: any;
  public stake: any;
  public startTime: any;
  public ticketModel = new Ticket();
  public ticketState;
  public myTickets: MyTickets[];
  public amount = new Amount();
  public disabled = false;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: this.authService.getAuthorizationToken()
    })
  };
  public httpHeaderOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public googleAnalyticsService: GoogleAnalyticsService
  ) {

  }

  public updateTicket(stakeUpdate: number = null) {
    if (this.checkTicket) {
      const ticket: any = JSON.parse(localStorage.getItem('ticket'));
      if (ticket != null) {
        this.googleAnalyticsService.eventEmitter('update_ticket', 'ticket', 'update', 'click', 1);
        if (document.getElementById('removeEvent')) {
          document.getElementById('removeEvent').style.display = '';
        }
        this.EventID = ticket.EventID;
        this.startTime = ticket.startTime;
        this.ticketModel.EventID = this.EventID;
        this.ticketModel.startTime = this.startTime;
        this.ticketModel.selectionName = ticket.selectionName;
        this.ticketModel.odd = ticket.odd;

        if (document.getElementById('home') != null) {
          this.home = ticket.t.Team1ID;
          this.ticketModel.home = this.home;
          document.getElementById('home').innerText = ticket.t.Team1ID;
        }
        if (document.getElementById('away') != null) {
          this.away = ticket.t.Team2ID;
          this.ticketModel.away = this.away;
          document.getElementById('away').innerText = ticket.t.Team2ID;
        }
        if (document.getElementById('selection') != null) {
          this.selection = ticket.s;
          this.ticketModel.selection = this.selection;
          document.getElementById('selection').innerText = ticket.s;
        }
        if (document.getElementById('selectionName') != null) {
          this.ticketModel.selectionName = ticket.selectionName;
          document.getElementById('selectionName').innerText = ticket.selectionName;
        }
        // tslint:disable-next-line: max-line-length
        if (document.getElementById('odd') != null) {
          this.odd = ticket.o;
          this.ticketModel.odd = ticket.o;
          document.getElementById('odd').innerText = ticket.o;

        } else if (ticket !== undefined && ticket.o !== undefined) {
          const oddEl = document.getElementById('odd') as HTMLInputElement;
          if (oddEl) {
            oddEl.innerText = ticket.o;
          }
        }
        if (document.getElementById('stake') != null && ticket.stake != null && stakeUpdate != null && ticket.stake !== stakeUpdate) {
          this.ticketModel.stake = stakeUpdate > 100 ? 100 : stakeUpdate;
          localStorage.setItem('ticket', JSON.stringify({
            EventID: this.EventID,
            startTime: this.startTime,
            t: ticket.t,
            s: ticket.s,
            o: ticket.o,
            selectionName: ticket.selectionName,
            stake: stakeUpdate > 100 ? 100 : stakeUpdate
          }));
          const stakeElement = document.getElementById('stake') as HTMLInputElement;
          stakeElement.value = this.ticketModel.stake.toString();
        } else if (document.getElementById('stake') != null && ticket.stake != null) {
          this.stake = ticket.stake;
          this.ticketModel.stake = this.stake;
          const stakeElement = document.getElementById('stake') as HTMLInputElement;
          stakeElement.value = ticket.stake;
          localStorage.setItem('ticket', JSON.stringify({
            EventID: this.EventID,
            startTime: this.startTime,
            t: ticket.t,
            s: ticket.s,
            o: ticket.o,
            selectionName: ticket.selectionName,
            stake: ticket.stake
          }));
        }
      }
    }
  }

  public add2Ticket(e, s, o, sn) {
    const Team1ID = e.Team1ID;
    const Team2ID = e.Team2ID;
    const EventID = e.EventID;
    const startTime = e.StartTime;
    const selectionName = sn;

    const stakeEl = document.getElementById('stake') as HTMLInputElement;
    const stake = stakeEl != null && stakeEl.value != null ? stakeEl.value : 100;
    const t = { Team1ID, Team2ID };
    o = Number(o).toFixed(2);
    this.googleAnalyticsService.eventEmitter('add_to_ticket', 'ticket', 'add', 'click', 1);
    localStorage.setItem('ticket', JSON.stringify({ EventID, startTime, t, s, o, stake, selectionName }));
    this.updateTicket();
  }

  public submitTicket() {
    if (this.authService.isLoggedIn()) {
      if (this.disabled === false) {
        this.disabled = true;
        this.googleAnalyticsService.eventEmitter('submit_ticket', 'ticket', 'submit', 'click', 1);
        this.http.post<Ticket>(`${environment.backend.submitTicket}`,
          {
            EventID: this.EventID,
            StartTime: this.startTime,
            home: this.home,
            away: this.away,
            selection: this.selection,
            odd: this.odd,
            stake: this.stake
          }, this.httpHeaderOptions)
          .subscribe((res) => {
            if (res) {
              this.ticketState = res,
                document.getElementById('modalBody').innerHTML = '<h3>' + res + '</h3>',
                this.showModal(), this.removeEvent(), this.getAmmount(), this.disabled = false;
            }
          });
      }
    } else {
      // tslint:disable-next-line: max-line-length
      document.getElementById('modalBody').innerHTML = '<h3 class="text-center text-danger">Morate biti ulogovani da bi odigrali tiket.</h3>',
        this.showModal();
    }
  }

  protected showModal() {
    return $('#ticketModal').modal({
      keyboard: true,
      focus: true,
      show: true,
      backdrop: true
    });
  }

  public checkTicket() {
    return localStorage.getItem('ticket') != null ? true : false;
  }

  public removeEvent() {
    if (document.getElementById('home') != null) {
      document.getElementById('home').innerText = null;
    }
    if (document.getElementById('away') != null) {
      document.getElementById('away').innerText = null;
    }
    if (document.getElementById('selection') != null) {
      document.getElementById('selection').innerText = null;
    }
    if (document.getElementById('odd') != null) {
      document.getElementById('odd').innerText = null;
    }
    if (document.getElementById('selectionName') != null) {
      document.getElementById('selectionName').innerText = null;
    }
    if (document.getElementById('removeEvent') != null) {
      document.getElementById('removeEvent').style.display = 'none';
    }
    return localStorage.removeItem('ticket');
  }

  public async getAmmount() {
    if (this.authService.isLoggedIn()) {
      const getAmountURL = isDevMode() ? '/get_amount' : environment.backend.baseURL + '/get_amount';
      return await this.http.post<Amount>(getAmountURL, {}).subscribe((res) => {
        this.amount.daily = res.daily == null ? 0 : res.daily;
        this.amount.monthly = res.monthly == null ? 0 : res.monthly;
      }), error => {
        this.amount.daily = 0, this.amount.monthly = 0;
      };
    }
  }

  public async getTickets() {
    const getTicketsURL = isDevMode() ? '/get_tickets' : environment.backend.baseURL + '/get_tickets';
    return await this.http.get<MyTickets[]>(getTicketsURL).toPromise().then((res) => {
      this.myTickets = res;
    });
  }

}
