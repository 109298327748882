<app-header></app-header>
<div id="popup" class="popup__wrapper" (click)="closePopup()">
    <div class="popup__container">
        <a
            href="https://promo.meridianbet.rs/sr/category/244/page/4794?affiliate=200127&campaign=6677&refer=QTk1NzM0QkVFQkE2QjI0NkMwNjEzNENDMjA1QTczQjc=">
            <img src='./assets/20spinovadobrodoslice.jpg' alt="Meridian Kazino">
        </a>
    </div>
</div>
<router-outlet></router-outlet>
<app-footer *ngIf="isHomePage"></app-footer>