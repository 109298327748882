import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';

// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  private isMobileResolution: boolean;
  title = 'Meridian Tipster';

  constructor(private authService: AuthService, public router: Router) {
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
      //document.addEventListener('mouseout', this.onMouseOut);
    }
    // Global site tag (gtag.js) - Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-154348776-1', { 'page_path': event.urlAfterRedirects });
      }
    });
  }

  get isLoggedIn() { return this.authService.isLoggedIn(); }

  get getIsMobileResolution(): boolean { return this.isMobileResolution; }

  get isHomePage(): boolean {
    return (document.location.pathname === '/') ? true : false;
  }

  get checkTicket() {
    return localStorage.getItem('ticket') != null ? true : false;
  }

  public closePopup() {
    document.getElementById('popup').style.display = 'none';
  }

  public onMouseOut(event) {
    if (
      event.clientY < 0 &&
      event.relatedTarget == null &&
      event.target.nodeName.toLowerCase() !== 'select') {
      // Remove this event listener
      document.removeEventListener('mouseout', this.onMouseOut);
      // Show the popup
      document.getElementById('popup').style.display = 'block';
    }
  }
}
