<div class="container text-center">
    <div class="row" style="margin: 10px;">
        <div class="col"></div>
        <div class="col">
            <span aria-label="Page navigation example">
                <span class="pagination">
                    <span *ngFor="let week of Weeks">
                        <span class="page-item"
                            matTooltip="{{week.date.week_start | date:'dd.MM.yyyy' }} - {{week.date.week_end | date:'dd.MM.yyyy'}}">
                            <a class="page-link"
                                (click)="getRangkins(week.week,week.date.week_start,week.date.week_end)">{{week.week}}
                                <span *ngIf="week.current">nedelja</span></a>
                        </span>
                    </span>
                </span>
            </span></div>
        <div class="col"></div>
    </div>
    <h1>Rang lista</h1>
    <div class="table-responsive-lg">
        <table class="table table-striped table-sm" style="width: 100%">
            <thead class="table-secondary text-center">
                <th>#</th>
                <th>Ime</th>
                <th>Odigrano</th>
                <th class="text-success">Dobitni</th>
                <th class="text-danger">Izgubljeni</th>
                <th class="text-primary">Otvoreni</th>
                <th class="text-danger">Kvota 1</th>
                <th>Poena</th>
                <th>Procenat</th>
            </thead>
            <tbody>
                <tr *ngFor="let rank of RankList; let i = index" class="text-center" [attr.data-index]="i" [ngClass]="{'font-weight-bold table-success': boldUser(rank)}">
                    <td *ngIf="i==0" style='background:red; color: white'>{{i+1}}</td>
                    <td *ngIf="i==1" style='background:blue; color: white'>{{i+1}}</td>
                    <td *ngIf="i==2" style='background:lightblue; color: black'>{{i+1}}</td>
                    <td *ngIf="i>2">{{i+1}}</td>
                    <td>{{rank.player_lastname}} {{rank.player_firstname | slice:0:1}}.</td>
                    <td (click)="showLast5Tickets(rank.player_firstname,rank.player_lastname, rank.id)">{{rank.played}}</td>
                    <td>{{rank.win}}</td>
                    <td>{{rank.lost}}</td>
                    <td>{{rank.opened}}</td>
                    <td>{{rank.void}}</td>
                    <td>{{rank.ukupno}}</td>
                    <td *ngIf="rank.percentage < 50" class="text-danger">{{rank.percentage}}%</td>
                    <td *ngIf="rank.percentage >= 50" class="text-success">{{rank.percentage}}%</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal fade" id="ticketModal" tabindex="-1" role="dialog" aria-labelledby="ticketModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5>Poslednjih 5 tiketa</h5>
                </div>
                <div class="modal-body" id="modalBody" style="padding: 0px;">
                    <table class="table table-sm table-hover table-responsive">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" style="padding: 3px;">Vreme</th>
                                <th scope="col" style="padding: 3px;">Domaćin</th>
                                <th scope="col" style="padding: 3px;">Gost</th>
                                <th scope="col" style="padding: 3px;">Igra</th>
                                <th scope="col" style="padding: 3px;">Kvota</th>
                                <th scope="col" style="padding: 3px;">Dobitak</th>
                                <th scope="col" style="padding: 3px;">Stanje</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of Tickets"
                                [ngClass]="(x.stanje == 'Dobitan') ? 'text-success': ((x.stanje == 'Gubitan') ? 'text-danger': '')">
                                <td class="red">{{x.startTime | date:'dd.MM. HH:mm' }}</td>
                                <td class="red">
                                    <div class="timovi">{{x.home}}</div>
                                </td>
                                <td class="red">
                                    <div class="timovi">{{x.away}}</div>
                                </td>
                                <td class="red">{{x.selection}}</td>
                                <td class="red">{{x.odd}}</td>
                                <td class="red">
                                    <span *ngIf="x.stanje === 'Gubitan'">
                                        -100
                                    </span>
                                    <span *ngIf="x.stanje === 'Kvota 1'">
                                        0
                                    </span>
                                    <span *ngIf="(x.stanje !== 'Gubitan') && (x.stanje !== 'Kvota 1')">
                                        {{x.win | number}}
                                    </span>
                                </td>
                                <td class="red">{{x.stanje}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        (click)="refresh()">Zatvori</button>
                </div>
            </div>
        </div>
    </div>
</div>