import { Component, OnInit, isDevMode } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { RankTop25 } from '../_models/ranktop25';
import { environment } from 'src/environments/environment';
import { StorageMap } from '@ngx-pwa/local-storage';

declare var $: any;
@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingComponent implements OnInit {
  public RankList: RankTop25[];
  public httpHeaderOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(
    private http: HttpClient,
    private storage: StorageMap
  ) { }

  ngOnInit() {
    this.getTop25();
  }

  public async getTop25() {
    // tslint:disable-next-line: max-line-length
    const params = new HttpParams().set('betshop_id', `${environment.betshop_id}`);
    const getTop25 = isDevMode() ? '/get_top25' : environment.backend.baseURL + '/get_top25';
    return await this.http.get<RankTop25[]>(getTop25, { params }).toPromise().then((res) => {
      this.RankList = res;
    });
  }

  public hideRankings() {
    if (document.getElementById('rankings').style.display === 'table') {
      document.getElementById('rankings').style.display = 'none';
      document.getElementById('ranking_text').innerHTML = '<i class="fas fa-eye"></i>';
    } else {
      document.getElementById('rankings').style.display = 'table';
      document.getElementById('ranking_text').innerHTML = '<i class="far fa-eye-slash"></i>';
    }
  }

}
