import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TodayComponent } from './today/today.component';
import { NgxLoadingModule } from 'ngx-loading';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TicketComponent } from './ticket/ticket.component';
import { DashboardComponent } from './profile/dashboard/dashboard.component';
import { httpInterceptorProviders } from './http-interceptors/index';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieModule, CookieOptionsProvider } from 'ngx-cookie';
import { JwtModule } from '@auth0/angular-jwt';
import { RankingComponent } from './ranking/ranking.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AllComponent } from './ranking/all/all.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_LANGUAGE, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RulesComponent } from './rules/rules.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { GoogleAnalyticsService } from './google-analytics.service';
import { LogoutComponent } from './logout/logout.component';
import { LeagueComponent } from './league/league.component';
import { BannersComponent } from './banners/banners.component';
import { MessagingService } from './messaging.service';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    TodayComponent,
    HeaderComponent,
    FooterComponent,
    TicketComponent,
    DashboardComponent,
    LoginComponent,
    RankingComponent,
    AllComponent,
    RulesComponent,
    LogoutComponent,
    LeagueComponent,
    BannersComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
    CookieModule.forRoot({}),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: ['merbet.com', 'meridianbet.com']
      }
    }),
    BrowserAnimationsModule,
    NgxMatDrpModule,
    MatTooltipModule,
    MatSortModule,
    MatExpansionModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    RecaptchaV3Module,
    MatDatepickerModule,
    CdkAccordionModule,
    CdkTreeModule,
    CdkTableModule,
    CdkStepperModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    httpInterceptorProviders,
    CookieOptionsProvider,
    GoogleAnalyticsService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LfzZbgUAAAAAGkQTKBoZx4hxgyIkUnTgjgeiCt2' },
    { provide: RECAPTCHA_LANGUAGE, useValue: 'sr' },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LfzZbgUAAAAAGkQTKBoZx4hxgyIkUnTgjgeiCt2' } as RecaptchaSettings, },
    MessagingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('token');
}
