import { Component, OnInit, isDevMode, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  public footerHTML: any;

  constructor(private http: HttpClient, public sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.getRules().finally(() => {
      setTimeout(() => { // not so smart way to do
        document.getElementById('copy-year').appendChild(
          document.createTextNode(String(new Date().getFullYear()))
          );
        }, 1337);
    });
  }

  public async getRules() {
    const getFooterURL = isDevMode() ? '/get_footer' : environment.backend.baseURL + '/get_footer';
    const params = new HttpParams().set('betshop_id', `${environment.betshop_id}`);
    return await this.http.get<any>(getFooterURL, { params }).toPromise().then((res) => {
      this.footerHTML = this.sanitizer.bypassSecurityTrustHtml(res.html);
    });
  }

}
