<div class="top10">
    <h2>TOP 10</h2>
    <div class="player-label">
        <div class="lfloat redni">&nbsp;</div>
        <div class="lfloat ime">Ime</div>
        <div class="lfloat poeni">Poeni</div>
    </div>
    <div class="player" *ngFor="let rank of RankList; let i = index" class="text-center" [attr.data-index]="i">
        <div class="lfloat redni">{{i+1}}</div>
        <div class="lfloat ime">{{rank.player_lastname}} {{rank.player_firstname | slice:0:1}}.</div>
        <div class="lfloat poeni">{{rank.ukupno}}</div>
    </div>
    <button class="btn btn-sm btn-outline-danger" routerLink="/rang-lista">Prikaži sve</button>
</div>