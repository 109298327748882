import { Component, OnInit, isDevMode } from '@angular/core';
import { League } from '../_models/league';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Ranking } from '../_models/ranktop25';
import { Weeks } from '../_models/weeks';
import { StorageMap } from '@ngx-pwa/local-storage';
import { User } from '../_models/user';
declare var $: any;
@Component({
  selector: 'app-league',
  templateUrl: './league.component.html',
  styleUrls: ['./league.component.css']
})
export class LeagueComponent implements OnInit {
  public League: League;
  public RankList: Array<Ranking> = new Array<Ranking>();
  public Weeks: Array<Weeks> = Array<Weeks>();
  public userData: User;
  public Tickets: any;
  public inLeague: any;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private http: HttpClient,
    private storage: StorageMap
  ) { }

  ngOnInit() {
    this.isInLeague();
    this.getLeagueData().then().finally(() => {
      this.getWeeks().then(() => {
        this.getRangkins(this.Weeks[0].week, this.Weeks[0].date.week_start, this.Weeks[0].date.week_end);
      });
    });
    this.getUserDataFromStorage();
  }

  public async isInLeague() {
    const isInLeagueURL = isDevMode() ? '/is_in_league' : environment.backend.baseURL + '/is_in_league';
    await this.http.post(isInLeagueURL, {}, this.httpOptions).toPromise().then((res) => {
      this.inLeague = res;
    });
    return this.inLeague;
  }

  public getUserDataFromStorage() {
    this.storage.get('data').subscribe((data) => {
      this.userData = data as User;
    });
  }

  public async getWeeks() {
    const getWeeksURL = isDevMode() ? '/get_weeks' : environment.backend.baseURL + '/get_weeks';
    return await this.http.post<Array<Weeks>>(getWeeksURL, {}).toPromise().then((res) => {
      this.Weeks = res;
    });
  }

  public boldUser(user) {
    return this.userData.id === user.id ? true : false;
  }

  public async getLeagueData() {
    const getLeagueDataURL = isDevMode() ? '/get_league_data' : environment.backend.baseURL + '/get_league_data';
    await this.http.post<League>(getLeagueDataURL, {}, this.httpOptions).toPromise().then((res: League) => {
      this.League = res;
    });
    return this.League;
  }

  public async getRangkins(week: any = null, from: any = null, to: any = null) {
    const getLeagueRankingsURL = isDevMode() ? '/get_league_rankings' : environment.backend.baseURL + '/get_league_rankings';
    const params = new HttpParams().set('week', week)
      .set('from', from)
      .set('to', to)
      .set('betshop_id', '' + environment.betshop_id + '')
      .set('league_id', '' + this.League.league_id + '');
    return await this.http.get<Array<Ranking>>(getLeagueRankingsURL,
      { params }).toPromise().then((res) => {
        this.RankList = res;
      });
  }


  public async showLast5Tickets(firstname, lastname, id) {
    const showLast5URL = isDevMode() ? '/show_last5_tickets' : environment.backend.baseURL + '/show_last5_tickets';
    return await this.http.post<any>(showLast5URL,
      // tslint:disable-next-line: max-line-length object-literal-shorthand
      { player_firstname: firstname, player_lastname: lastname, from: this.Weeks[0].date.week_start, to: this.Weeks[0].date.week_end, id: id })
      .toPromise().then((res) => {
        this.Tickets = res, this.showModal();
      });
  }

  protected showModal() {
    return $('#ticketModal').modal({
      keyboard: true,
      focus: true,
      show: true,
      backdrop: true
    });
  }

  refresh(): void {
    return $('#ticketModal').modal({
      keyboard: true,
      focus: true,
      show: false,
      backdrop: true
    });
  }

}
