/* tslint:disable */
import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { TicketService } from 'src/app/ticket/ticket.service';
import { MyTickets } from 'src/app/_models/ticket';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgxDrpOptions, PresetItem, Range, RangeStoreService, CalendarOverlayService, ConfigStoreService } from 'ngx-mat-daterange-picker';
import { DatePipe } from '@angular/common';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Sort } from '@angular/material/sort';
import { User } from 'src/app/_models/user';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LeagueService } from 'src/app/league/league.service';
import { League } from 'src/app/_models/league';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [
    DatePipe,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'warn' }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { color: 'warn' }
    },
    RangeStoreService, CalendarOverlayService, ConfigStoreService
  ]
})

export class DashboardComponent implements OnInit {

  range: Range = { fromDate: new Date(), toDate: new Date() };
  from: any;
  to: any;
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];

  public myTickets: MyTickets[];
  public sortedData: MyTickets[];
  public TicketStates: TicketState;
  public state: number;
  public panelOpenState = true;
  public userData: User;
  public inLeague: any;
  public leagueCode: any;
  public League: League;

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private authService: AuthService,
    private tickets: TicketService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private storage: StorageMap,
    private leagueService: LeagueService
  ) { }

  ngOnInit() {
    this.getUserData();
    this.isInLeague();
    this.getLeagueData();
    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: 'dd.MM.yyyy',
      range: { fromDate: today, toDate: today },
      applyLabel: 'Potvrdi',
      locale: 'sr_Latn_RS',
      cancelLabel: 'Otkaži',
      placeholder: 'Izaberi datum',
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
        hasBackdrop: true
      }
    };
    this.authService.checkToken();
    this.getTickets(1,
      this.datePipe.transform(this.range.fromDate, 'dd.MM.yyyy'), this.datePipe.transform(this.range.toDate, 'dd.MM.yyyy')
    ).finally(() => {
      this.sortedData = this.myTickets.slice();
    });
  }

  public async getTickets(state: number, from: any, to: any) {
    const getTicketsURL = isDevMode() ? '/get_tickets' : environment.backend.baseURL + '/get_tickets';
    this.state = state;
    return await this.http.post<MyTickets[]>(getTicketsURL,
      // tslint:disable-next-line: object-literal-shorthand
      { state: state, from: from, to: to }).toPromise().then((res) => {
        this.myTickets = res;
        this.sortedData = this.myTickets.slice();
      });
  }

  // handler function that receives the updated date range object
  async updateRange(range: Range) {
    this.range = range;
    this.from = this.datePipe.transform(range.fromDate, 'dd.MM.yyyy');
    this.to = this.datePipe.transform(range.toDate, 'dd.MM.yyyy');
    await this.getTickets(this.state, this.from, this.to).finally(() => {
      this.sortedData = this.myTickets.slice();
    });
  }

  public setOverlay() {
    const newWindowWidth = $(window).width();
    if (newWindowWidth < 1024) {
      // @ts-ignore-next-line
      $('div[id^=\'cdk-overlay-\']')[0].style = 'left: 15% !important;overflow-y: scroll;overflow-x: scroll !important;';
      // @ts-ignore-next-line
      $("div[class^=\'ngx-mat-drp-calendar-container ng-trigger ng-trigger-transformPickerOverlay\']")[0].style = "display: inline !important";
      // @ts-ignore-line
    }
  }

  // helper function to create initial presets
  setupPresets() {
    const backDate = (numOfDays) => {
      // tslint:disable-next-line: no-shadowed-variable
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      { presetLabel: 'Juče', range: { fromDate: yesterday, toDate: today } },
      { presetLabel: 'Prethodnih 7 dana', range: { fromDate: minus7, toDate: today } },
      { presetLabel: 'Prethodnih 30 dana', range: { fromDate: minus30, toDate: today } },
      { presetLabel: 'Ovaj mesec', range: { fromDate: currMonthStart, toDate: currMonthEnd } },
      { presetLabel: 'Prošli mesec', range: { fromDate: lastMonthStart, toDate: lastMonthEnd } }
    ];
  }

  sortData(sort: Sort) {
    const data = this.myTickets.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'startTime': return compare(a.startTime, b.startTime, isAsc);
        case 'time': return compare(a.time, b.time, isAsc);
        default: return 0;
      }
    });
  }

  public async getUserData() {
    return await this.storage.get('data').subscribe((data: User) => { this.userData = data; });
  }

  get logout() {
    return this.authService.logout();
  }

  get isMobile() {
    const newWindowWidth = $(window).width();
    if (newWindowWidth > 1024) { return false; } else { return true; }
  }

  public async isInLeague() {
    const isInLeagueURL = isDevMode() ? '/is_in_league' : environment.backend.baseURL + '/is_in_league';
    await this.http.post(isInLeagueURL, {}, this.httpOptions).toPromise().then((res) => {
      this.inLeague = res;
    });
    return this.inLeague;
  }

  public async getLeagueData() {
    const getLeagueDataURL = isDevMode() ? '/get_league_data' : environment.backend.baseURL + '/get_league_data';
    await this.http.post<League>(getLeagueDataURL, {}, this.httpOptions).toPromise().then((res: League) => {
      this.League = res;
    });
    return this.League;
  }

  public async createLeague() {
    var league_name = ((document.getElementById("league_name") as HTMLInputElement).value);
    if (league_name != undefined && league_name != '') {
      var responseData;
      await this.http.post(`${environment.backend.baseURL + '/create_league'}`, { league_name: league_name }, this.httpOptions).toPromise().then((res: League) => {
        responseData = res;
      });
      return window.location.reload();
    }
  }

  public async joinLeague() {
    var league_code = ((document.getElementById("league_code") as HTMLInputElement).value);
    if (league_code != undefined && league_code != '') {
      await this.http.post(`${environment.backend.baseURL + '/join_league'}`, { league_code: league_code }, this.httpOptions).toPromise().then((res: League) => { });
      return window.location.reload();
    }
  }

  public copyToClipboard() {
    var copyText = (document.getElementById("invite_code") as HTMLInputElement);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export enum TicketState {
  Otvoren = 1,
  Dobitan = 2,
  Gubitan = 3
}
