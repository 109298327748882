import { Component, OnInit, isDevMode } from '@angular/core';
import { Ranking } from '../../_models/ranktop25';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Weeks } from 'src/app/_models/weeks';
import { StorageMap } from '@ngx-pwa/local-storage';
import { User } from 'src/app/_models/user';

declare var $: any;
@Component({
  selector: 'app-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.css']
})
export class AllComponent implements OnInit {
  public RankList: Array<Ranking> = new Array<Ranking>();
  public Weeks: Array<Weeks> = Array<Weeks>();
  public Tickets: any;
  public userData: User;

  constructor(
    private http: HttpClient,
    private storage: StorageMap
  ) { }

  ngOnInit() {
    this.getWeeks().then(() => {
      this.getRangkins(this.Weeks[0].week, this.Weeks[0].date.week_start, this.Weeks[0].date.week_end);
    });
    this.getUserDataFromStorage();
  }

  public getUserDataFromStorage() {
    this.storage.get('data').subscribe((data) => {
      this.userData = data as User;
    });
  }

  public boldUser(user) {
    return this.userData.id === user.id ? true : false;
  }

  public async getRangkins(week: any = null, from: any = null, to: any = null) {
    const getRankingsURL = isDevMode() ? '/get_rankings' : environment.backend.baseURL + '/get_rankings';
    const params = new HttpParams().set('week', week).set('from', from).set('to', to).set('betshop_id', '' + environment.betshop_id + '');
    return await this.http.get<Array<Ranking>>(getRankingsURL,
      { params }).toPromise().then((res) => {
        this.RankList = res;
      });
  }

  public async showTickets(firstname, lastname) {
    return await this.http.post<any>(`${environment.backend.baseURL + '/show_tickets'}`,
      // tslint:disable-next-line: max-line-length object-literal-shorthand
      { player_firstname: firstname, player_lastname: lastname, from: this.Weeks[0].date.week_start, to: this.Weeks[0].date.week_end })
      .toPromise().then((res) => {
        this.Tickets = res, this.showModal();
      });
  }

  public async showLast5Tickets(firstname, lastname, id) {
    const showLast5URL = isDevMode() ? '/show_last5_tickets' : environment.backend.baseURL + '/show_last5_tickets';
    return await this.http.post<any>(showLast5URL,
      // tslint:disable-next-line: max-line-length object-literal-shorthand
      { player_firstname: firstname, player_lastname: lastname, from: this.Weeks[0].date.week_start, to: this.Weeks[0].date.week_end, id: id })
      .toPromise().then((res) => {
        this.Tickets = res, this.showModal();
      });
  }

  public async getWeeks() {
    const getWeeksURL = isDevMode() ? '/get_weeks' : environment.backend.baseURL + '/get_weeks';
    return await this.http.post<Array<Weeks>>(getWeeksURL, {}).toPromise().then((res) => {
      this.Weeks = res;
    });
  }

  refresh(): void {
    return $('#ticketModal').modal({
      keyboard: true,
      focus: true,
      show: false,
      backdrop: true
    });
  }

  protected showModal() {
    return $('#ticketModal').modal({
      keyboard: true,
      focus: true,
      show: true,
      backdrop: true
    });
  }

}
