import { Component, OnInit, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css']
})
export class BannersComponent implements OnInit {
  public bannerHTML: any;
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getBanners();
  }

  public async getBanners() {
    const getFooterURL = isDevMode() ? '/get_banners' : environment.backend.baseURL + '/get_banners';
    const params = new HttpParams().set('betshop_id', `${environment.betshop_id}`);
    return await this.http.get<any>(getFooterURL, { params }).toPromise().then((res) => {
      this.bannerHTML = res;
    });
  }

}
