import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { isDevMode } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { StorageMap } from '@ngx-pwa/local-storage';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public serverUrl = isDevMode() ? '/login-api' : environment.backend.baseURL + '/login';
  public serverTestUrl = isDevMode() ? '/test' : environment.backend.baseURL + '/test';
  errorData: {};
  public error: any;
  redirectUrl: string;
  public token: any;
  public userData: User;
  public loginResponse: any;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private storage: StorageMap
  ) { }


  login(username: string, password: string) {
    this.loginResponse = this.http.post<any>(`${this.serverUrl}`,
      { account_id: username, player_email: password, betshop_id: environment.betshop_id },
      this.httpOptions)
      .pipe(
        map(user => {
          if (user && user.token) {
            this.storage.set('data', user).subscribe(() => { });
            localStorage.setItem('token', user.token);
          } else if (user.error) {
            this.loginResponse = user.error;
          }
        })
      );
    return this.loginResponse;
  }

  isLoggedIn() {
    return localStorage.getItem('token') != null ? true : false;
  }

  getAuthorizationToken() {
    return localStorage.getItem('token');
  }



  public getUserInfo() {
    return this.storage.get('data');
  }

  public checkToken() {
    return this.http.post<any>(`${this.serverTestUrl}`, {}).subscribe((res) => {
      if (res != null && res.error != null) {
        this.logout();
      }
    });
  }

  logout() {
    localStorage.removeItem('token');
    this.storage.delete('data').subscribe(() => { });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
