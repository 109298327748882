<main>
    <ng-template #customLoadingTemplate>
    </ng-template>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading>

    <!-- <div role="alert"
        [ngClass]="(this.showInfo=='true') ? 'alert alert-danger alert-dismissible fade show' : ((this.showInfo === 'false') ? 'alert alert-danger alert-dismissible fade d-none': 'alert alert-danger alert-dismissible fade show')">
        <strong style="margin-left: 6px;" class="text-center">
            <h1><i class="far fa-calendar-alt"></i> Takmičenje počinje u ponedeljak 8. februara 2021. </h1>
        </strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="read_info()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->

    <article class="table lfloat" *ngIf="undefined !== results && results !== 'undefined'">
        <h2>PONUDA</h2>
        <header class="table-header" id="myHeader">
            <div class="info-wrapper lfloat">
                <div class="tipovanje">TIPOVANJE</div>
                <div class="selection-box">
                    <div class="lfloat vreme">Vreme</div>
                    <div class="lfloat tim">Domaćin</div>
                    <div class="lfloat tim">Gost</div>
                </div>
            </div>
            <div class="games-wrapper lfloat">
                <div class="game-box3">
                    <div class="game-label">Konačan ishod</div>
                    <div class="selection-box">
                        <div class="selection3">1</div>
                        <div class="selection3">X</div>
                        <div class="selection3">2</div>
                    </div>
                </div>
                <div class="game-box3">
                    <div class="game-label">Dupla šansa</div>
                    <div class="selection-box">
                        <div class="selection3">12</div>
                        <div class="selection3">1X</div>
                        <div class="selection3">X2</div>
                    </div>
                </div>
                <div class="game-box2">
                    <div class="game-label">Golovi</div>
                    <div class="selection-box">
                        <div class="selection2">0-2</div>
                        <div class="selection2">3+</div>
                    </div>
                </div>
                <div class="game-box2">
                    <div class="game-label">GG/NG</div>
                    <div class="selection-box">
                        <div class="selection2">GG</div>
                        <div class="selection2">NG</div>
                    </div>
                </div>
            </div>
        </header>
        <div class="modal fade" id="ticketModal" tabindex="-1" role="dialog" aria-labelledby="ticketModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body" id="modalBody">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            (click)="refresh()">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ponuda">
            <!-- RED 1 -->
            <section class="table-row" *ngFor="let e of results; let i = index" [attr.data-index]="i+1"
                (click)="clickEvent(i)">
                <div class="info-wrapper lfloat">
                    <div>
                        <div class="lfloat vreme desk">{{e.StartTime|date:"HH:mm"}}</div>
                        <div class="lfloat tim" [ngbTooltip]="team1Position" container="body">
                            <ng-template #team1Position>
                                <span *ngIf="e.Team1Position != null"><i><b>{{e.LeagueID}}</b></i><br>Position:
                                    {{e.Team1Position}}</span>
                                <span *ngIf="e.Team1Position == null"><i><b>{{e.LeagueID}}</b></i></span>
                            </ng-template>
                            <span [innerHTML]="e.Team1ID"></span>
                        </div>
                        <div class="lfloat vreme mob"> {{e.StartTime|date:"HH:mm"}} </div>
                        <div class="lfloat tim" [ngbTooltip]="team2Position" container="body">
                            <ng-template #team2Position>
                                <span *ngIf="e.Team2Position != null"><i><b>{{e.LeagueID}}</b></i><br>Position:
                                    {{e.Team2Position}}</span>
                                <span *ngIf="e.Team2Position == null"><i><b>{{ e.LeagueID }}</b></i></span>
                            </ng-template>
                            <span [innerHTML]="e.Team2ID"></span>
                        </div>
                    </div>
                </div>
                <div class="games-wrapper lfloat" id='gw{{i+1}}'>
                    <div class="game-box3">
                        <div class="game-label mob">Konačan ishod</div>
                        <div class="label-box mob">
                            <div class="selection3">1</div>
                            <div class="selection3">X</div>
                            <div class="selection3">2</div>
                        </div>
                        <div class="odds-box">
                            <a (click)="e.FinalScore[0]?.Price>1 && add2Ticket(e,e.FinalScore[0]?.Name,e.FinalScore[0]?.Price,'Konačan ishod',$event)"
                                class="selection3">{{ (e.FinalScore[0]?.Price>1?e.FinalScore[0]?.Price:'0.99') |
                                number:'1.2-4' }}</a>
                            <a (click)="e.FinalScore[2]?.Price>1 && add2Ticket(e,e.FinalScore[2]?.Name,e.FinalScore[2]?.Price,'Konačan ishod',$event)"
                                class="selection3">{{ (e.FinalScore[2]?.Price>1?e.FinalScore[2]?.Price:'0.99') |
                                number:'1.2-4' }}</a>
                            <a (click)="e.FinalScore[1]?.Price>1 && add2Ticket(e,e.FinalScore[1]?.Name,e.FinalScore[1]?.Price,'Konačan ishod',$event)"
                                class="selection3">{{ (e.FinalScore[1]?.Price>1?e.FinalScore[1]?.Price:'0.99') |
                                number:'1.2-4' }}</a>
                        </div>
                    </div>
                    <div class="game-box3">
                        <div class="game-label mob">Dupla šansa</div>
                        <div class="label-box mob">
                            <div class="selection3">12</div>
                            <div class="selection3">1X</div>
                            <div class="selection3">X2</div>
                        </div>
                        <div class="odds-box">
                            <a (click)="e.DoubleChance[0]?.Price>1 && add2Ticket(e,e.DoubleChance[0]?.Name,e.DoubleChance[0]?.Price,'Dupla šansa',$event)"
                                class="selection3">
                                {{ (e.DoubleChance[0]?.Price>1?e.DoubleChance[0]?.Price:'0.99') | number:'1.2-4' }}
                            </a>
                            <a (click)="e.DoubleChance[1]?.Price>1 && add2Ticket(e,e.DoubleChance[1]?.Name,e.DoubleChance[1]?.Price,'Dupla šansa',$event)"
                                class="selection3">
                                {{ (e.DoubleChance[1]?.Price>1?e.DoubleChance[1]?.Price:'0.99') | number:'1.2-4' }}
                            </a>
                            <a (click)="e.DoubleChance[2]?.Price>1 && add2Ticket(e,e.DoubleChance[2]?.Name,e.DoubleChance[2]?.Price,'Dupla šansa',$event)"
                                class="selection3">
                                {{ (e.DoubleChance[2]?.Price>1?e.DoubleChance[2]?.Price:'0.99') | number:'1.2-4' }}
                            </a>
                        </div>
                    </div>
                    <div class="game-box2">
                        <div class="game-label mob">Golovi</div>
                        <div class="label-box mob">
                            <div class="selection2">0-2</div>
                            <div class="selection2">3+</div>
                        </div>
                        <div class="odds-box">
                            <a (click)="e.Goals[0]?.Price>1 && add2Ticket(e,e.Goals[0]?.Name,e.Goals[0]?.Price,'Golovi',$event)"
                                class="selection2">
                                {{ (e.Goals[0]?.Price>1?e.Goals[0]?.Price:'0.99') | number:'1.2-4' }}
                            </a>
                            <a (click)="e.Goals[1]?.Price>1 && add2Ticket(e,e.Goals[1]?.Name,e.Goals[1]?.Price,'Golovi',$event)"
                                class="selection2">
                                {{ ((e.Goals[1]?.Price>1)?e.Goals[1]?.Price:'0.99') | number:'1.2-4' }}
                            </a>
                        </div>
                    </div>
                    <div class="game-box2">
                        <div class="game-label mob">GG/NG</div>
                        <div class="label-box mob">
                            <div class="selection2">GG</div>
                            <div class="selection2">NG</div>
                        </div>
                        <div class="odds-box">
                            <a class="selection2"
                                (click)="e.GGNG[0]?.Price>1 && add2Ticket(e,e.GGNG[0]?.Name,e.GGNG[0]?.Price,'GG/NG',$event)">
                                {{ (e.GGNG[0]?.Price>1?e.GGNG[0]?.Price:'0.99') | number:'1.2-4'}}
                            </a>
                            <a class="selection2"
                                (click)="e.GGNG[1]?.Price>1 && add2Ticket(e,e.GGNG[1]?.Name,e.GGNG[1]?.Price,'GG/NG',$event)">
                                {{ ((e.GGNG[1]?.Price>1)?e.GGNG[1]?.Price:'0.99') | number:'1.2-4' }}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </article>

    <aside>
        <div id="ticket-box" class="ticket-box" style="bottom: -350px;"
            *ngIf="undefined !== results && results !== 'undefined'">
            <h2 class=""><span class="mob ticketdown" (click)="ticketDown()"></span>MOJ TIKET</h2>
            <div class="lfloat ticket-tim" id="home">{{home}}</div>
            <div class="lfloat ticket-tim" id="away">{{away}}</div>
            <div class="lfloat cancel" (click)='removeEvent()' id='removeEvent' style="display: none;">X</div>
            <div class="lfloat ticket-game" id='selectionName'></div>
            <div class="lfloat ticket-selection" id="selection">{{selection}}</div>
            <div id="odd" class="lfloat ticket-odd">{{odd}}</div>

            <div class="potvrda">
                <label>Uplata:</label>
                <div class="stake">
                    <input id="stake" name="stake" type="number" class="form-control form-control-sm" value="100"
                        size="1" min="5" max="100" (change)="updateStake($event)">
                </div>
                <div id="submit" class="submit" (click)="submitTicket()">Potvrdi</div>
            </div>

            <div class="preostalo">Preostalo dnevnih tiketa:
                &nbsp;<span>{{(amount.daily != null ? amount.daily/100:'') | number:'1.0-0'}}</span>
            </div>
        </div>
        <div class="ticket-button mob" id="ticketup" (click)='ticket_button()' *ngIf="checkTicket()">
            <div class="itemon" *ngIf="checkTicket()">1</div>
            <div class="itemon" *ngIf="!checkTicket()"></div>
        </div>

        <app-ranking *ngIf="undefined !== results && results !== 'undefined'"></app-ranking>

        <app-banners></app-banners>
    </aside>
</main>