export const environment = {
  production: true,
  backend: {
    baseURL: 'https://tipsterapi.meridianbet.com',
    submitTicket: 'https://tipsterapi.meridianbet.com/submit_ticket'
  },
  betshop_id: 1677,
  siteAddress: 'https://meridianbet.rs/sr',
  siteRegisterAddress: 'https://meridianbet.rs/sr/registracija',
  affiliateSuffix: 'affiliate=200127&campaign=6677&refer=QTk1NzM0QkVFQkE2QjI0NkMwNjEzNENDMjA1QTczQjc=',
  firebase: {
	  apiKey: "AIzaSyDaPXXpG6X1_5pN8PI78u6WnmBY5k4O02I",
	  authDomain: "tipster-bbe7e.firebaseapp.com",
	  projectId: "tipster-bbe7e",
	  storageBucket: "tipster-bbe7e.appspot.com",
	  messagingSenderId: "627726320423",
	  appId: "1:627726320423:web:e5a59bb31886f84c209f02",
	  measurementId: "G-CDMJQLGYFZ"
	}
};
