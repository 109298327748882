export class Ticket {
    EventID: number;
    startTime: any;
    home: any;
    away: any;
    selection: any;
    odd: any;
    stake: number;
    win: number;
    selectionName: string;
}
export class MyTickets extends Ticket {
    account_id: any;
    ticket_id: any;
    state: any;
    time: any;
    result: any;
    stake: number;
}

export class Stake {
    stake: any;
}
