<div id="fullscreen" class="container text-center">
    <mat-accordion>
        <!--#region Moj nalog -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <i class="fa fa-id-card"></i>&nbsp; Moj nalog
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field class="example-full-width"
                *ngIf="userData != undefined && userData.account_id != undefined">
                <input matInput value="{{userData.account_id}}" [readonly]="true">
            </mat-form-field><br>
            <mat-form-field class="example-full-width"
                *ngIf="userData != undefined && userData.player_firstname != undefined">
                <input matInput value="{{userData.player_firstname}}" [readonly]="true">
            </mat-form-field>
            <mat-form-field class="example-full-width"
                *ngIf="userData != undefined && userData.player_lastname != undefined">
                <input matInput value="{{userData.player_lastname}}" [readonly]="true">
            </mat-form-field><br>
            <mat-form-field class="example-full-width"
                *ngIf="userData != undefined && userData.player_email != undefined">
                <input matInput value="{{userData.player_email}}" [readonly]="true">
            </mat-form-field><br>
            <button mat-mini-fab color="warn" matTooltip="Nalog će biti obrisan samo na Tipster-u!" [disabled]="true">
                <i class="fas fa-trash-alt"></i>
            </button>
        </mat-expansion-panel>
        <!--#endregion Moj nalog-->

        <!--#region Moji Tiketi -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <i class="fas fa-ticket-alt"></i>&nbsp; Moji tiketi
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <!--#region Filteri -->
            <mat-radio-group>
                <mat-radio-button name="state" value="2" (click)="getTickets(2,from,to)">Dobitan &nbsp;&nbsp;
                </mat-radio-button>
                <mat-radio-button name="state" value="3" (click)="getTickets(3,from,to)">Gubitan&nbsp;&nbsp;
                </mat-radio-button>
                <mat-radio-button name="state" value="1" (click)="getTickets(1,from,to)" checked>Otvoren&nbsp;&nbsp;
                </mat-radio-button>
                <mat-radio-button name="state" value="" (click)="getTickets(null,from,to)">Sve&nbsp;&nbsp;
                </mat-radio-button>
            </mat-radio-group>

            <ngx-mat-drp (selectedDateRangeChanged)="updateRange($event)" [options]="options" #dateRangePicker
                (click)="setOverlay()">
            </ngx-mat-drp>
            <!--#endregion Filteri -->

            <table class="table table-responsive" matSort (matSortChange)="sortData($event)">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" mat-sort-header="startTime">Vreme</th>
                        <th scope="col">Domaćin</th>
                        <th scope="col">Gost</th>
                        <th scope="col">Igra</th>
                        <th scope="col">Kvota</th>
                        <th scope="col">Stanje</th>
                        <th scope="col">Uplata</th>
                        <th scope="col">Dobitak</th>
                        <th scope="col" mat-sort-header="created_at">Odigran</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ticket of sortedData">
                        <td>{{ticket.startTime | date:'dd.MM.yyyy HH:mm' }}</td>
                        <td>{{ticket.home}}</td>
                        <td>{{ticket.away}}</td>
                        <td>{{ticket.selection}}</td>
                        <td>{{ticket.odd}}</td>
                        <td *ngIf="ticket.stanje == 'Dobitan'" class="text-success" [ngbTooltip]="result"
                            container="body">
                            <ng-template #result>
                                <span
                                    *ngIf="ticket.result != null">{{ticket.home}}<br>({{ticket.result}})<br>{{ticket.away}}</span>
                            </ng-template>
                            {{ticket.stanje}}
                        </td>
                        <td *ngIf="ticket.stanje == 'Kvota 1'" class="text-success" [ngbTooltip]="result"
                            container="body">
                            <ng-template #result>
                                <span
                                    *ngIf="ticket.result != null">{{ticket.home}}<br>({{ticket.result}})<br>{{ticket.away}}</span>
                            </ng-template>
                            {{ticket.stanje}}
                        </td>
                        <td *ngIf="ticket.stanje == 'Gubitan'" class="text-danger" [ngbTooltip]="result"
                            container="body">
                            <ng-template #result>
                                <span
                                    *ngIf="ticket.result != null">{{ticket.home}}<br>({{ticket.result}})<br>{{ticket.away}}</span>
                            </ng-template>
                            {{ticket.stanje}}
                        </td>
                        <td *ngIf="ticket.stanje == 'Otvoren'">
                            {{ticket.stanje}}
                        </td>
                        <td> {{ticket.stake}} </td>
                        <td>
                            <span *ngIf="ticket.stanje === 'Gubitan'">
                                -100
                            </span>
                            <span *ngIf="ticket.stanje === 'Kvota 1'">
                                0
                            </span>
                            <span *ngIf="(ticket.stanje !== 'Gubitan') && (ticket.stanje !== 'Kvota 1')">
                                {{ticket.win | number}}
                            </span>
                        </td>
                        <td>{{ticket.created_at | date:'dd.MM.yyyy HH:mm'}}</td>
                    </tr>
                </tbody>
            </table>

        </mat-expansion-panel>
        <!--#endregion Moji Tiketi -->

        <!--#region Moja liga -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <i class="far fa-futbol"></i>&nbsp; Moja liga
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>

                <!--#region Udju u ligu -->
                <mat-expansion-panel [hidden]="this.inLeague">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fas fa-sign-in-alt"></i> &nbsp; Uđi u ligu
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-form-field>
                        <input matInput id='league_code' maxlength="16" placeholder="Kod za ulazak u ligu">
                    </mat-form-field>
                    <br>
                    <button matInput class="btn btn-sm btn-outline-success" (click)="joinLeague()">
                        <i class="fas fa-sign-in-alt"></i> Uđi u ligu
                    </button>
                </mat-expansion-panel>
                <!--#endregion Udju u ligu -->

                <!--#region Kreiraj ligu -->
                <mat-expansion-panel [hidden]="this.inLeague">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="far fa-plus-square"></i> &nbsp; Kreiraj ligu
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="example-container">
                        <mat-form-field>
                            <input matInput id='league_name' placeholder="Naziv lige">
                        </mat-form-field>
                        <br>
                        <button matInput class="btn btn-sm btn-outline-success" (click)="createLeague()">
                            <i class="far fa-plus-square"></i> &nbsp; Kreiraj ligu
                        </button>
                    </div>
                </mat-expansion-panel>
                <!--#endregion Kreiraj ligu -->

                <!--#region Pozovi u ligu -->
                <mat-expansion-panel [hidden]="!this.inLeague">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fas fa-phone"></i> &nbsp; Pozovi u ligu
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="text-center">
                        <h3>{{ (this.League !== undefined && this.League.league_name !== undefined) ? (this.League.league_name) : '' }}
                        </h3>
                        <mat-form-field>
                            <input id="invite_code" matInput
                                value="{{ (this.League !== undefined && this.League.league_code !== undefined) ? (this.League.league_code) : '' }}">
                            <mat-hint style="margin-left: 50px;">Kod za poziv u ligu</mat-hint>
                            <mat-icon matSuffix (click)="copyToClipboard()"><i class="far fa-copy"></i></mat-icon>
                        </mat-form-field>
                        <br>
                    </div>
                    <hr>
                    <button class="btn btn-md btn-outline-danger" routerLink="/liga">Idi na ligu</button>
                </mat-expansion-panel>
                <!--#endregion Pozovi u ligu -->

            </mat-accordion>

        </mat-expansion-panel>
        <!--#endregion Moja liga-->

    </mat-accordion>

    <button routerLink="/" class="btn btn-outline-danger" (click)="logout" style="margin: 10px;" *ngIf="isMobile">
        <i class="fas fa-sign-out-alt"></i> Izloguj se
    </button>
</div>