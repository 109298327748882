import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TodayComponent } from './today/today.component';
import { DashboardComponent } from './profile/dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { AllComponent } from './ranking/all/all.component';
import { RulesComponent } from './rules/rules.component';
import { LogoutComponent } from './logout/logout.component';
import { LeagueComponent } from './league/league.component';
import { BannersComponent } from './banners/banners.component';

const routes: Routes = [
  { path: '', component: TodayComponent },
  { path: 'today', component: TodayComponent },
  { path: 'login', component: LoginComponent },
  { path: 'uloguj-se', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'izloguj-se', component: LogoutComponent },
  {
    path: 'profile',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        children: [
          { path: 'dashboard', component: DashboardComponent },
          { path: '', component: DashboardComponent }
        ],
      }
    ]
  },
  {
    path: 'profil',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        children: [
          { path: 'dashboard', component: DashboardComponent },
          { path: '', component: DashboardComponent }
        ],
      }
    ]
  },
  { path: 'rang-lista', canActivate: [AuthGuard], component: AllComponent },
  { path: 'ranking', canActivate: [AuthGuard], component: AllComponent },
  { path: 'league', canActivate: [AuthGuard], component: LeagueComponent },
  { path: 'liga', canActivate: [AuthGuard], component: LeagueComponent },
  { path: 'rang-lista/svi', canActivate: [AuthGuard], component: AllComponent },
  { path: 'ranking/all', canActivate: [AuthGuard], component: AllComponent },
  { path: 'rules', component: RulesComponent },
  { path: 'pravila', component: RulesComponent },
  { path: 'baneri', component: BannersComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
