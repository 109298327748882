import { Component, OnInit, isDevMode, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RulesComponent implements OnInit {
  public rulesHTML;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getRules();
  }

  public async getRules() {
    const getRulesURL = isDevMode() ? '/get_rules' : environment.backend.baseURL + '/get_rules';
    const params = new HttpParams().set('betshop_id', `${environment.betshop_id}`);
    return await this.http.get<any>(getRulesURL, { params }).toPromise().then((res) => {
      this.rulesHTML = res.html;
    });
  }
}
