import { Component, OnInit, isDevMode, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TicketService } from '../ticket/ticket.service';
import { AuthService } from '../auth/auth.service';
import { Amount } from '../_models/amount';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as bootstrap from 'bootstrap';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessagingService } from '../messaging.service';

@Component({
  selector: 'app-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.css'],
})
export class TodayComponent implements OnInit {

  public loading = true;
  public endpoint = isDevMode() ? '/today-matches' : environment.backend.baseURL + '/today';
  public results: any;
  protected notFound = undefined;
  public home: any;
  public away: any;
  public selection: any;
  public odd: any;
  public amount = new Amount();
  public username: any;
  public siteRegisterAddress = environment.siteRegisterAddress;
  public stake: number;
  public showInfo: any;
  public message;

  constructor(
    private _HTTP: HttpClient,
    private ticket: TicketService,
    private authService: AuthService,
    private storage: StorageMap,
    private renderer: Renderer2,
    private messagingService: MessagingService
  ) { }


  protected getTodayMatches() {

    return this._HTTP.get<Event[]>(this.endpoint)
      .subscribe(
        restItems => {
          this.results = restItems as Event[];
          this.loading = false;
        }, err => {
          this.results = undefined;
          this.loading = false;
        }
      );
  }

  add2Ticket(e, s, o, sn, what = null) {
    this.toggleClass(what);
    this.ticket.add2Ticket(e, s, o, sn);
  }

  get isLoggedIn() { return this.authService.isLoggedIn(); }

  get logout() {
    return this.authService.logout();
  }

  public getAmmount(): Observable<Amount> {
    const getAmountURL = isDevMode() ? '/get_amount' : environment.backend.baseURL + '/get_amount';
    return this.authService.getAuthorizationToken() != null
      ? this._HTTP.post<Amount>(getAmountURL, {}) : new Observable<Amount>();
  }

  public submitTicket() {
    this.ticket.submitTicket();
    this.ticket.getAmmount();
  }

  public checkTicket() {
    return this.ticket.checkTicket();
  }

  public updateStake(stake: any) {
    this.ticket.stake = stake.target.value;
    this.ticket.updateTicket(stake.target.value);
  }

  public async firstName() {
    if (this.isLoggedIn) {
      const usr = this.storage.get('data').subscribe((user) => {
        // tslint:disable-next-line: no-string-literal
        this.username = user['player_firstname'];
      });
    }
  }

  public refresh(): void {
    window.location.reload();
  }

  public ticket_button() {
    $('.ticket-button').animate({ right: '-70px' });
    $('.ticket-box').animate({ bottom: '0px' });
  }

  public ticketDown() {
    $('.ticket-box').animate({ bottom: '-350px' });
    $('.ticket-button').animate({ right: '30px' });
  }

  public removeEvent() {
    this.ticket.removeEvent();
  }

  public show_info() {
    if (localStorage.getItem('najava') == null) {
      localStorage.setItem('najava', JSON.stringify(true));
    }
    return localStorage.getItem('najava');
  }

  public read_info() {
    localStorage.setItem('najava', JSON.stringify(false));
  }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    window.addEventListener('scroll', () => {
      if (document.location.pathname === '/') {
        const newWindowWidth = $(window).width();
        if (newWindowWidth > 1024) {
          const header = document.getElementById('myHeader');
          if (header !== undefined && header !== null) {
            const sticky = (header.offsetTop !== undefined && header.offsetTop !== null) ? header.offsetTop : 0;
            if (window.pageYOffset > sticky) {
              header.classList.add('sticky');
            } else {
              header.classList.remove('sticky');
            }
            const ticketHeader = document.getElementById('ticket-box');
            const thOffset = ticketHeader.offsetTop != null ? ticketHeader.offsetTop : 0;
            const stickyTicket = ticketHeader.offsetTop != null ? thOffset : 0;
            if (window.pageYOffset > stickyTicket) {
              ticketHeader.classList.add('stickyTicket');
            } else {
              ticketHeader.classList.remove('stickyTicket');
            }
          }
        }
      }
    });
    this.authService.checkToken();
    this.getTodayMatches();
    this.ticket.updateTicket();
    this.getAmmount().subscribe(
      (res) => {
        this.amount.daily = res.daily;
        this.amount.monthly = res.monthly;
      }
    );
    this.showInfo = this.show_info();
  }

  public clickEvent(id: number) {
    const newWindowWidth = $(window).width();
    if (newWindowWidth < 1024) {
      id = id + 1;
      const selector = '#gw' + id;
      $('div[id^=\'gw\']').hide();
      $(selector).show();
      $([document.documentElement, document.body]).animate({
        scrollTop: $(selector).offset().top - 50
      }, 500);
    }
  }

  public toggleClass(event: any, klasa = 'selected') {
    const elems = document.querySelectorAll('a.selected');
    [].forEach.call(elems, (el) => {
      el.classList.remove('selected');
    });
    const hasClass = event.target.classList.contains(klasa);
    if (hasClass) {
      this.renderer.removeClass(event.target, klasa);
    } else {
      this.renderer.addClass(event.target, klasa);
    }
  }
}
